import React, {useState, useEffect} from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from '@material-ui/core/DialogActions';
import Slide from '@material-ui/core/Slide';

const useStyles = makeStyles(() => ({
    root: {
        paddingTop: "16px",
        paddingLeft: "16px",
        paddingRight: "16px",
        maxWidth : 1080,
        display: 'flex',
        alignItems:'center',
        justifyContent: 'center',
        margin: 'auto',
    },
    paper: {
        padding: "16px"
    },
    notes: {
        width: '100%'
    },
    button: {
        marginTop: '16px'
    },
    value: {
        whiteSpace: 'pre-line'
    },
    box: {
        display: 'flex',
        alignItems: 'center'
    },
    flex: {
        flex: 1
    }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const TakeoffNotes = ({ fullScreen, value, onChange, showNotes, disabled }) => {
    const classes = useStyles();
    const [notes, setNotes] = useState("");
    const [showField, setShowField] = useState(false);

    useEffect( () => {
        setNotes(value);
    }, [value]);

    const saveNotes = () => {
        onChange(notes);
        setShowField(false);
    }
    const cancelDialog = () => {
        setNotes(value)
        setShowField(false);
    }

    return (
        <Grid item xs={12}>
            {
                showField ?
                    <Dialog
                        maxWidth={'lg'}
                        fullWidth
                        fullScreen={fullScreen}
                        open={showField}
                        onClose={cancelDialog}
                        TransitionComponent={Transition}
                        aria-labelledby="responsive-dialog-title"
                    >
                        <DialogTitle id="responsive-dialog-title">
                            { notes ? "Edit" : "Add"} Notes
                        </DialogTitle>
                        <DialogContent>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        label="Notes"
                                        multiline
                                        rows={3}
                                        disabled={disabled}
                                        rowsMax="10"
                                        value={notes}
                                        onChange={(event) => setNotes(event.target.value)}
                                        className={classes.notes}
                                        margin="normal"
                                        variant="outlined"
                                        autoFocus={true}
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={cancelDialog}
                            >
                                Cancel
                            </Button>
                            <Button
                                onClick={saveNotes}
                                color={ 'secondary' }
                                variant="contained"
                                disabled={disabled || (!value && !notes)}
                            >
                                Save
                            </Button>
                        </DialogActions>
                    </Dialog>
                :
                <div className={classes.box}>
                    <div className={classes.flex}>
                        {
                            showNotes &&
                            <Typography variant="body1" className={classes.value}>
                                {notes?.replace("<br/>","\\n")}
                            </Typography>
                        }
                    </div>
                    <div>
                        <Button
                            disabled={disabled}
                            onClick={() => setShowField(true)}
                            color={notes ? 'default' : 'secondary' }
                            className={classes.button}
                            variant="contained"
                            size="small"
                        >
                            { notes ? "Edit Note" : "Add Note"}
                        </Button>
                    </div>
                </div>
            }
        </Grid>
    )
}

export default TakeoffNotes