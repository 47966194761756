import { gql } from '@apollo/client';

export const TakeoffList_Fragment = gql`fragment TakeoffListParts on Takeoff {
    id,
    copiedFromId copiedFromCode copyType isAdditionalOrder
    code,
    customerType,
    customer {
        name
    },
    prospect {
        firstname, lastname
    },
    createdBy{username, name},
    updatedBy{username},
    createdAt,
    updatedAt,
    doorsNo trimNo hardwareNo openingNo shelvingNo indvItemNo indvInstallNo
    site{
        lot, civic, city, modelType, modelElevation, modelOption, siteMeasure, contractPricing, installationRequired
    },
    status
    mine
}
`;

export const Mini_Takeoff_Fragment = gql`fragment MiniTakeoffParts on Takeoff {
    id,
    code,
    copiedFromId copiedFromCode copyType
    customer {name accountNo pricingTier},
    createdBy{username name},
    createdAt,
    status
}
`;
export const User_Fragment             = gql`fragment UserParts on User {id username email phone warehouse name role createdBy{username name} managedBy {id name} team {id name} createdAt enabled confirmedAt}`;
export const PricingTiers_Fragment     = gql`fragment PricingTiersParts on PricingTier {lp00 lp02 lp04 lp05 lp06 lp08 lp10 lp15 gb1 gb2 wn3 wn4 la3 la4 rmg1 rmg2 rmg3 tw dt hh}`;
export const SpecProduct_Fragment      = gql`fragment SpecProductParts on GenericMeasureItem { code price { ...PricingTiersParts } description uom attributes } ${PricingTiers_Fragment}`;
export const GMProduct_Fragment        = gql`fragment GMProductParts on GenericMeasureItem  { count isSpecial ...SpecProductParts } ${SpecProduct_Fragment}`;
export const Product_Fragment          = gql`fragment ProductParts on ProductType           { count isSpecial  code price { ...PricingTiersParts } description uom attributes } ${PricingTiers_Fragment}`;
export const Pricing_Fragment          = gql`fragment PricingParts on Pricing {lp00 lp02 lp04 lp05 lp06 lp08 lp10 lp15 gb1 gb2 wn3 wn4 la3 la4 rmg1 rmg2 rmg3 tw dt hh}`;

export const Door_Fragment             = gql`fragment DoorParts on GenericMeasureItem {
    id itemIndex unitNumber title description uom price { ...PricingTiersParts } code width cutdownHeight hasCutdown doorFunction handleDirection isReverse isHalfTrim handleType handleMode jambWidth pocket_mode doorstopper_mode doorstopper_mode_left doorstopper_mode_right notes isSpecial isBifold isDouble latching operating rightHandleMode leftHandleMode addBackband backbandSides addArchitrave architraveSides architraveWidth hingesPerDoor images materialType openingDepth quantity hasExtraService extraService cutdownLocation bipassTrackLength
            attributes additionalItem {bill labour po_required}
            customHinges hasCustomHinge hasCustomLockset customLockset
            sheet { ...ProductParts }
            hardware {
                double {
                    left  {
                        doorstopper {type code count price { ...PricingTiersParts } description uom attributes isSpecial}
                        lever {type code count price { ...PricingTiersParts } description uom attributes isSpecial }
                        knob {type code count price { ...PricingTiersParts } description uom attributes isSpecial }
                    }
                    right {
                        doorstopper {type code count price { ...PricingTiersParts } description uom attributes isSpecial }
                        lever {type code count price { ...PricingTiersParts } description uom attributes isSpecial }
                        knob {type code count price { ...PricingTiersParts } description uom attributes isSpecial }
                    }
                }
                doorstopper         { ...GMProductParts }
                hinge               { ...GMProductParts }
                lever               { ...GMProductParts }
                knob                { ...GMProductParts }
                bifold_knob         { ...GMProductParts }
                flushbolt           { ...GMProductParts }
                catches             { ...GMProductParts }
                pocket              { ...GMProductParts }
                barn_door_track     { ...GMProductParts }
                finger_pull         { ...GMProductParts }
                bipass_door_track   { ...GMProductParts }
            }
            trim     {
                jamb       { type ...GMProductParts }
                casing     { type ...GMProductParts }
                doorstop   { type ...GMProductParts }
                architrave { type ...GMProductParts }
                flat_stock { type ...GMProductParts }
                backband   { type ...GMProductParts }
                burlap     { type ...GMProductParts }
                sheet      { type ...GMProductParts }
            }
            opening_service
            prehang_service { ...ProductParts }
            install_service { ...ProductParts }
            createdAt
            updatedAt
} ${Product_Fragment} ${GMProduct_Fragment} ${PricingTiers_Fragment}`;
export const Window_Fragment           = gql`fragment WindowParts on GenericMeasureItem { 
    id itemIndex unitNumber title type size attributes operating handleType layout quantity width perimeter height isSpecial backbandSides architraveSides addArchitrave addBackband addBuildout buildoutDepth sillDepth sillLength architraveWidth hardwareOptions jambWidth materialType openingDepth hasCloser hasBurlap isHigh highOpeningLocation addSplit addSill materialLength d4sLength casingLength handrailLength continuousHandrail addHandrail addCasing addD4s notes images coveLength doorstopper_mode doorstopper_mode_left doorstopper_mode_right closerCount customHeader headerQuantity headerMaterial extHardwareMode extHardwareMode2 extHandleMode extHandleMode2 hasExtraService extraService
    additionalItem {bill labour po_required}
    sheet { ...ProductParts }
    trim {
        architrave      { ...GMProductParts }
        sheet           { ...GMProductParts }
        casing          { ...GMProductParts }
        backband        { ...GMProductParts }
        buildout        { ...GMProductParts }
        doorstop        { ...GMProductParts }
        sill            { ...GMProductParts }
        jamb            { ...GMProductParts }
        burlap          { ...GMProductParts }
        curve           { ...GMProductParts }
        flat_stock      { ...GMProductParts }
        d4s             { ...GMProductParts }
        cove            { ...GMProductParts }
        quarter_round   { ...GMProductParts }
        handrail        { ...GMProductParts }
        archway_header  { ...GMProductParts }
        attic_lid       { ...GMProductParts }
        attic_hatch     { ...GMProductParts }
        attic_foam_tape { ...GMProductParts }
        column          { ...GMProductParts }
    }
    hardware {
        closer              { ...GMProductParts }
        gripset             { ...GMProductParts }
        knob                { ...GMProductParts }
        lever               { ...GMProductParts }
        deadbolt            { ...GMProductParts }
        handrail_bracket    { ...GMProductParts }
        double {
            left  {
                doorstopper {type code price { ...PricingTiersParts } description uom attributes count isSpecial}
                gripset     {type code price { ...PricingTiersParts } description uom attributes count isSpecial}
                knob        {type code price { ...PricingTiersParts } description uom attributes count isSpecial}
                lever       {type code price { ...PricingTiersParts } description uom attributes count isSpecial}
            }
            right {
                doorstopper {type code price { ...PricingTiersParts } description uom attributes count isSpecial}
                gripset     {type code price { ...PricingTiersParts } description uom attributes count isSpecial}
                knob        {type code price { ...PricingTiersParts } description uom attributes count isSpecial}
                lever       {type code price { ...PricingTiersParts } description uom attributes count isSpecial}
            }
        }
        doorstopper         { ...GMProductParts }
    }
    opening_service
    install_service { ...ProductParts }
    createdAt
    updatedAt } ${Product_Fragment} ${GMProduct_Fragment} ${PricingTiers_Fragment}`;

export const Trim_Fragment             = gql`fragment TrimParts on GenericMeasureItem { id itemIndex unitNumber title count type notes images product { ...ProductParts } additionalItem {bill labour po_required} install_service { ...ProductParts } createdAt updatedAt } ${Product_Fragment}`;
export const Hardware_Fragment         = gql`fragment HardwareParts on GenericMeasureItem { id itemIndex unitNumber title count type notes images product { ...ProductParts } additionalItem {bill labour po_required} createdAt updatedAt } ${Product_Fragment}`;
export const Shelving_Fragment         = gql`fragment ShelvingParts on GenericMeasureItem { 
    id itemIndex dimensions miscellaneous unitNumber title material depth type layout quantity width notes images additional_rods additional_brackets
    product  { attributes description code price { ...PricingTiersParts } count uom isSpecial }
    additionalItem {bill labour po_required}
    trim     {
        cleat       { ...GMProductParts }
    }
    hardware {
        rod      { ...GMProductParts }
        bracket  { ...GMProductParts }
        joiner   { ...GMProductParts }
    }
    wallDesigns { 
        wall1 { hasJoiner designs { design width size  product { ...ProductParts } } }
        wall2 { hasJoiner designs { design width size product { ...ProductParts } } }
        wall3 { hasJoiner designs { design width size product { ...ProductParts } } }
    }
    accessories { code count price { ...PricingTiersParts } description uom attributes }
    install_service { code count price { ...PricingTiersParts } description uom attributes }
    addedInstall
    createdAt
    updatedAt

 } ${Product_Fragment} ${GMProduct_Fragment}`;

export const Takeoff_Fragment          = gql`fragment TakeoffParts on Takeoff {
    id code status reviewType installPricingMode installAt customerType notes customer_notes isAdditionalOrder isLocked lockedAt
    publicCode linkExpiresAt
    location { value }
    additionalOrder { home_occupied rush_request date_required siteChange material riversideError notes additionalItem {bill labour po_required} }
    customer { name accountNo pricingTier phone email address address2 city province country postalCode pricingTier defaultPricingTier territory salesRep taxGroup currency createdAt}
    prospect { firstname lastname email phone company location gps address address2 city province leadSource postalCode country pricingTier }
    specs {
        notes
        products {
            hinge                  { ...SpecProductParts }
            bifold_knob            { ...SpecProductParts }
            flushbolt              { ...SpecProductParts }
            catches                { ...SpecProductParts }
            casing                 { ...SpecProductParts }
            doorstop               { ...SpecProductParts }
            backband               { ...SpecProductParts }
            shoe_moulding          { ...SpecProductParts }
            deadbolt               { ...SpecProductParts }
            closer                 { ...SpecProductParts }
            doorstopper            { ...SpecProductParts }
            wood                   { ...SpecProductParts }
            wire                   { ...SpecProductParts }
            d4s                    { ...SpecProductParts }
            flat_stock             { ...SpecProductParts }
            baseboard              { ...SpecProductParts }
            crown_moulding         { ...SpecProductParts }
            architrave             { ...SpecProductParts }
            sheet                  { ...SpecProductParts }
            finger_pull            { ...SpecProductParts }
            # bipass_door_track    { ...SpecProductParts }
            barn_door_track        { ...SpecProductParts }
            buildout               { ...SpecProductParts }
            burlap                 { ...SpecProductParts }
            panel_mould            { ...SpecProductParts }
            sill                   { ...SpecProductParts }
            jamb                   { ...SpecProductParts }
            jamb_84                { ...SpecProductParts }
            jamb_96                { ...SpecProductParts }
            quarter_round          { ...SpecProductParts }
            cove                   { ...SpecProductParts }
            handrail               { ...SpecProductParts }
            handrail_bracket       { ...SpecProductParts }
            opening_service        { ...SpecProductParts }
            shim                   { ...SpecProductParts }
            cleat                  { ...SpecProductParts }
            shiplap                { ...SpecProductParts }
            column                 { ...SpecProductParts }
            attic_lid              { ...SpecProductParts }
            attic_hatch            { ...SpecProductParts }
            attic_foam_tape        { ...SpecProductParts }
        }
        shelving {
            wire  { mode }
            cleat { supplier style species width thickness }
            organizer { color hardware }
        }
        door_treatment  { prep cutdown backset head }
        door  {type supplier style core thickness height frame finish panelProfile sticking material }
        sheet { species style thickness grade length }
        hardware {
            knob                { brand style finish rosette firerated }
            bifold_knob         { brand finish }
            lever               { brand style finish rosette firerated }
            gripset             { brand style finish }
            deadbolt            { brand style finish mode rosette firerated }
            closer              { brand style finish }
            pocket              { brand style finish rosette }
            barn_door_track     { brand style finish tracklength }
            bipass_door_track   { brand style }
            finger_pull         { finish size }
            hinge               { width height radius style finish brand extra_specs }
            flushbolt           { style width length finish }
            catches             { style finish }
            doorstopper         { finish }
            handrail_bracket    { style width finish }
        }
        trim {
            baseboard                   { supplier style species width thickness finish length }
            casing                      { supplier style species width thickness length }
            curve                       { style species finish }
            crown_moulding              { supplier style species width thickness length }
            shoe_moulding               { supplier style species width thickness }
            architrave                  { supplier style species width thickness length }
            jamb                        { supplier style species thickness width height finish }
            jamb_84                     { supplier style species thickness width height finish }
            jamb_96                     { supplier style species thickness width height finish }
            backband                    { supplier style species width thickness length }
            doorstop                    { supplier style species width thickness length }
            d4s                         { supplier species width thickness }
            flat_stock                  { supplier style finish species width thickness length }
            burlap                      { supplier style species width thickness length }
            panel_mould                 { supplier style species width thickness finish length }
            buildout                    { supplier style species width thickness }
            sill                        { supplier style species width thickness }
            cove                        { supplier species finish width thickness length }
            quarter_round               { supplier style species width length }
            handrail                    { supplier style species width thickness length }
            sheet                       { style species length thickness grade length }
            shim                        { style }
            shiplap                     { width supplier style thickness species finish length }
            column                      { width supplier style radius species length }
            attic_lid                   { style }
            attic_hatch                 { style }
            attic_foam_tape             { style }
        }
    }
    site   {name lot civic intersection city modelType modelElevation modelOption }
    measure {
        doors { ...DoorParts }
        windows { ...WindowParts }
        trim { ...TrimParts }
        hardware { ...HardwareParts }
        shelving { ...ShelvingParts }
        custom_products {
            id itemIndex unitNumber title type notes images count attributes
            product { ...ProductParts }
            additionalItem {bill labour po_required}
            createdAt
            updatedAt
        }
        custom_install_services {
            id itemIndex unitNumber title type notes images count attributes
            product { ...ProductParts }
            additionalItem {bill labour po_required}
            createdAt
            updatedAt
        }
    }
    createdBy{ username name }
    updatedBy{ username name }
    createdAt
}
${ PricingTiers_Fragment }
${ SpecProduct_Fragment }
${ GMProduct_Fragment }
${ Product_Fragment }
${ Door_Fragment}
${ Window_Fragment}
${ Trim_Fragment}
${ Hardware_Fragment}
${ Shelving_Fragment }
`;